/* GLOBAL */
:root {
  --app-height: 100%;
  --color-white: rgb(243, 236, 236);
  --modal-width: 95%;
  --modal-height: 85%;
  --work-width: 300px;
}

body {
  background-color: black;
  color: var(--color-white);
  height: 100vh;
  height: var(--app-height);
}

button {
  background-color: transparent;
  border: none;
}

input:focus,
textarea:focus {
  outline: none;
  background-color: rgb(206, 206, 206) !important;
}

label {
  display: block;
  font-size: 1.2rem;
}

li {
  line-height: 2rem;
}

img:active {
  outline: none;
  border: 0;
}

.route-menu {
  display: grid;
  grid-template-rows: repeat(5, 3rem);
}

/* TODO: Add mobile modal size */
/* TODO: hide scrollbars in mobile view
- make the gallery not override  */

/* Hiding scrollbars: */
.main-section-right {
  scrollbar-width: none; /* Hide the scrollbar for Firefox */
  -ms-overflow-style: none; /* Hide the scrollbar for Internet Explorer and Edge */
  overflow: -moz-scrollbars-none; /* Hide the scrollbar for legacy versions of Firefox */
}

.main-section-right::-webkit-scrollbar {
  display: none; /* Hide the scrollbar for WebKit-based browsers (e.g., Chrome, Safari) */
}

/* ITEMS */
.items-view {
  display: flex;
  justify-content: center;
  /* margin-right: 12%; */
}

.item-box {
  position: relative;
  width: var(--work-width);
  height: var(--work-width);
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
}

.item-title {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  display: none;
  font-size: 1.3rem;
  color: var(--color-white);
  padding-top: 3rem;
  opacity: 1 !important;
  z-index: 9;
}

.item-description {
  font-size: 1.1rem;
}

.item-img {
  width: 100%;
  object-fit: cover;
  height: var(--work-width);
  object-position: 0 40%;
}

/* Carousel */

/* TODO: if we have time, look into it:) */
.carousel-element {
  position: static;
  cursor: pointer;
}

/* HEADING */

.heading-container {
  text-align: center;
  white-space: nowrap;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Custom styles for the dropdown toggle (menu icon) */
.custom-dropdown-toggle {
  color: var(--color-white);
  background-color: transparent;
}

.custom-dropdown-toggle::after {
  display: none;
}

.custom-dropdown-menu {
  font-size: 1.8rem;
  border: 1px solid var(--color-white);
  background-color: black;
  color: var(--color-white);
  margin-top: 0.3rem;
  position: absolute;
  left: 0;
  top: calc(100% + 2rem); /* Position the dropdown below the icon */
}

/* FOOTER */

.footer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top: 1px solid var(--color-white);
  width: 100%;
  margin-top: 1.5rem;
}

.footer-icons {
  display: flex;
  gap: 2rem;
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.icon-hover:hover {
  animation: moveUpDown 2s infinite;
}

/* MODAL */
.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.85;
  z-index: 99;
}

.modal-content {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: calc((100% - var(--modal-height)) / 2);
  /* left: calc((100% - var(--modal-width)) / 2); */
  left: 0;
  /* width: var(--modal-width); */
  width: 100%;
  height: var(--modal-height);
  background-color: transparent;
  opacity: 1 !important;
  z-index: 999;
}

.enlarged-img-box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.enlarged-img {
  /* width: 50.4rem; */
  max-width: 35rem;
  margin: 1rem 2rem;
  cursor: pointer;
}

.enlarged-img-vertical {
  /* width: 32.265rem; */
  /* max-height: 30rem; */
  max-width: 25rem;
  margin: 1rem 2rem;
  cursor: pointer;
}

.enlarged-img-btn {
  opacity: 0.5;
  color: var(--color-white);
  width: 3rem;
  height: 3rem;
  font-size: 2rem;
}

.enlarged-img-btn:hover {
  opacity: 0.8;
  color: var(--color-white);
}

.work-container {
  position: relative;
  width: 100%;
}

.work-close-btn {
  position: absolute;
  top: 0.1rem;
  right: 3rem;
  color: var(--color-white);
  font-size: 2.5rem;
  filter: drop-shadow(1px 1px 1px rgb(0 0 0 / 0.4));
}

.thumbnail-gallery-container {
  display: flex;
  justify-content: center;
}

.thumbnail-gallery {
  overflow-x: auto;
  display: flex;
  gap: 18px;
  margin: 0 1rem;
  padding: 1rem;
}

.thumbnail-gallery div {
  cursor: pointer;
}

.thumbnail-img {
  width: 112.5px;
  height: 90px;
}

.thumbnail-img-vertical {
  width: 72px;
  height: 90px;
}

.work-description {
  text-align: center;
  font-size: 0.8rem;
  line-height: normal;
  padding: 0 1rem;
}

/* MENUS */
.route-menu-btn {
  font-size: 1.5rem;
  text-align: left;
}

.route-menu-btn,
.dropdown-item {
  display: block;
  background-color: transparent;
  border: none;
  color: var(--color-white);
}

.route-menu-btn-selected {
  font-size: 1.5rem;
  font-weight: 700;
}

.dropdown-item {
  --bs-dropdown-link-active-bg: black;
  box-shadow: none;
}

.dropdown-item:active,
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: var(--color-white);
  color: black;
}

/* CONTACT */
.msg-notification {
  padding: 0.6rem 0 0.6rem 0;
  font-weight: 700;
}

.main-page {
  display: grid;
}

.header {
  min-height: 100px;
}

.items-container {
  display: grid;
  gap: 1.5rem;
}

.contact-form-sender-info {
  display: flex;
  justify-content: space-between;
}

.contact-form-container {
  width: 35rem;
}

.contact-form-container input:not([type="submit"]),
.contact-form-container textarea {
  width: 100%;
}

.contact-form-container input[type="submit"] {
  width: 10rem;
  height: 3rem;
  background-color: rgb(139, 50, 117);
  color: #fff;
  border: none;
  margin-top: 1rem;
}

.contact-form-container input[type="submit"]:disabled {
  opacity: 0.6;
}

.contact-form-container label {
  padding: 1rem 0 0.3rem 0;
}

/* .carousel-element-title-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.carousel-element-title {
  font-size: 2.4rem;
}

.carousel-element-title-info {
  font-size: 1.2rem;
  text-align: center;
  padding: 0 2rem;
} */

/* Desktop View */
@media only screen and (min-width: 1201px) {
  /* MAIN SECTION */
  .main-page {
    grid-template-rows: 16vh 74vh 10vh;
  }

  .main-section {
    display: grid;
    grid-template-columns: 12rem auto;
    padding-top: 2rem;
  }

  .main-section-left-menu {
    margin-left: 2rem;
  }

  .main-section-right {
    overflow-y: auto;
  }

  .carousel-container {
    margin-right: 12%;
  }

  .carousel-element-img {
    width: 48rem;
  }

  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  /* HEADER */
  .header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
  }

  .dropdown-menu-container {
    display: none;
  }

  /* FOOTER */
  .footer-icons {
    margin-left: 4rem;
  }

  .footer-icons span {
    cursor: pointer;
  }

  /* BIO */
  .container-bio {
    display: flex;
    justify-content: center;
  }

  .bio-photo-container {
    margin-top: 1rem;
  }

  .bio-photo {
    height: 200px;
  }

  .bio-text {
    max-height: 50rem;
    height: 20rem;
    margin: 1rem 2rem 1rem 1rem;
    font-size: 1rem;
    text-align: justify;
  }

  /* EXHIBITIONS */
  .container-exhibitions {
    margin: 4rem 5rem 0rem 1rem;
    font-size: 1rem;
    text-align: justify;
  }

  /* CONTACT */
  .contact-container {
    margin: 1rem 3rem;
  }

  .contact-container h2 {
    padding-bottom: 0.1rem;
  }

  .contact-container p {
    font-size: 1rem;
  }

  .contact-form-container input,
  .contact-form-container textarea {
    border-radius: 5px;
    background-color: var(--color-white);
  }

  .contact-form-container label {
    margin-right: 0.5rem;
  }

  .contact-form-send-btn {
    background-color: var(--color-white);
    margin: 0.5rem 0 2rem 0;
  }

  .find-me-container h3 {
    padding-top: 2rem;
  }

  .find-me-container a {
    color: var(--color-white);
    text-decoration: none;
    font-weight: bolder;
    font-size: 1rem;
  }
}

/* Tablet view */
@media only screen and (min-width: 641px) and (max-width: 1200px) {
  .main-page {
    grid-template-rows: 20vh 70vh 10vh;
  }

  .main-section {
    display: grid;
    grid-template-columns: 1fr;
    overflow-y: auto;
    padding: 2rem;
  }

  .main-section-left-menu {
    display: none;
  }

  .header {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    padding-top: 1rem;
    margin-left: 4rem;
  }

  .custom-hamburger-icon {
    font-size: 2rem;
  }

  h1 {
    font-size: 2.3rem;
    padding-bottom: 1rem;
  }

  .dropdown-menu-container {
    text-align: start;
  }

  .footer-icons {
    margin-left: 4rem;
  }

  .carousel-element-img {
    width: 45rem;
  }

  .bio-text h2 {
    font-size: 1.5rem;
    padding-bottom: 1rem;
  }

  .bio-text {
    height: 50rem;
    margin: 1rem 2rem 1rem 1rem;
    font-size: 1.3rem;
    text-align: justify;
  }

  /* EXHIBITIONS */
  .container-exhibitions {
    margin: 1rem 6rem 0rem 9rem;
    font-size: 1.3rem;
    text-align: justify;
  }

  /* CONTACT */
  .contact-container {
    margin: 1rem 3rem;
  }

  .contact-container h2 {
    padding-bottom: 0.1rem;
  }

  .contact-container p {
    font-size: 1.3rem;
  }

  .contact-form-container input,
  .contact-form-container textarea {
    border-radius: 5px;
    background-color: var(--color-white);
  }

  .contact-form-container label {
    margin-right: 0.5rem;
  }

  .contact-form-send-btn {
    background-color: var(--color-white);
    margin: 0.5rem 0 2rem 0;
  }

  .find-me-container h3 {
    padding-top: 2rem;
  }

  .find-me-container a {
    color: var(--color-white);
    text-decoration: none;
    font-weight: bolder;
    font-size: 1.3rem;
  }

  .work-description {
    font-size: 1rem;
  }
}

@media (min-width: 1151px) {
  .container-bio {
    display: flex;
    justify-content: center;
    margin-right: 2rem;
  }

  .bio-photo {
    height: 25rem;
    margin: 1rem 2rem;
  }

  .items-container {
    grid-template-columns: repeat(3, var(--work-width));
  }
}

@media (min-width: 1601px) {
  .carousel-element-img {
    width: 60rem;
  }
}

@media (max-width: 1150px) {
  .bio-photo-container {
    display: flex;
    justify-content: center;
  }

  .carousel-element-img {
    min-width: 340px;
    max-width: 950px;
    width: 100%;
  }

  .bio-photo {
    height: 25rem;
    margin: 1rem 2rem;
    max-width: 20rem;
    width: 90%;
    object-fit: cover;
    object-position: 0 70%;
  }

  .items-container {
    grid-template-columns: repeat(2, var(--work-width));
  }

  .enlarged-img {
    width: 89.775%;
  }

  .enlarged-img-vertical {
    width: 57.645%;
  }

  .enlarged-img-btn {
    display: none;
  }

  .item-img {
    object-position: 0 60%;
  }
}

@media (max-width: 800px) {
  .items-container {
    grid-template-columns: 1fr;
    width: 100%;
  }

  .item-box-container {
    display: flex;
    justify-content: center;
  }

  .item-box {
    width: 90%;
    height: 200px;
    min-width: 300px;
  }

  .item-img {
    height: 200px;
  }

  .item-title {
    display: none !important;
  }

  .work-description {
    font-size: 1rem;
  }
}

/* Mobile View */
/* TODO: - add x to close the menu optional */
@media only screen and (max-width: 640px) {
  h1 {
    font-size: 2.2rem;
  }

  .main-page {
    grid-template-rows: 14vh 76vh 10vh;
  }

  .main-section {
    display: grid;
    grid-template-columns: 1fr;
    overflow-y: auto;
    padding: 1rem;
  }

  .main-section-left-menu {
    display: none;
  }

  .header {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    width: 100%;
  }

  .custom-hamburger-icon {
    font-size: 2rem;
  }

  .dropdown-menu-container {
    text-align: start;
    margin-left: 1rem;
  }

  .dropdown-item {
    font-size: 2rem;
  }

  .footer-icons {
    margin-left: 1rem;
  }

  .work-close-btn {
    top: -4rem;
    right: -4rem;
  }

  /* BIO */
  .bio-text h2 {
    font-size: 1.6rem;
    padding-bottom: 0.3rem;
  }

  .bio-text {
    margin: 2rem 1rem 1rem 1rem;
    font-size: 1.2rem;
    text-align: justify;
  }

  /* EXHIBITIONS */
  .container-exhibitions {
    font-size: 1.2rem;
    text-align: justify;
  }

  /* CONTACT */
  p {
    margin-bottom: 0 !important;
  }
  .contact-form-container label {
    font-size: 1.2rem;
  }

  .contact-form-container input {
    display: block;
    border-radius: 5px;
    background-color: var(--color-white);
  }

  .contact-form-container input[type="submit"] {
    margin-bottom: 1rem;
  }

  .contact-form-container {
    width: 100%;
  }

  .contact-form-container textarea {
    border-radius: 5px;
    background-color: var(--color-white);
  }

  .contact-container p {
    font-size: 1rem;
  }

  .contact-form-sender-info {
    display: block;
  }

  .find-me-container h2 {
    padding-bottom: 0.1rem;
  }

  .find-me-container a {
    color: var(--color-white);
    text-decoration: none;
    font-weight: bolder;
    font-size: 1.2rem;
  }
}

@media (max-device-width: 1150px) and (orientation: landscape) {
  h1 {
    margin-bottom: 0;
  }

  .header {
    margin-top: 0;
  }

  .main-page {
    grid-template-rows: 23vh 60vh 17vh;
  }
}
